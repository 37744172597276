import classNames from 'classnames';
const gridClassMultiplier = {
    MO: 1,
    ST: 2,
};
const getGridColNumber = (colnumnber) => {
    return colnumnber * (gridClassMultiplier[getId()] || gridClassMultiplier.MO);
};
const getId = () => {
    return window.brandId;
};
const getName = () => {
    return window.brand;
};
const is = (brandId) => {
    return getId() === brandId;
};
const nameIs = (brand) => {
    return getName() === brand;
};
const autoChooseClassnames = (classesObj, commonclasses) => {
    return classNames(classesObj[getId()], commonclasses);
};
const gridClassname = (monclerColumn = 12, brackpoint, type, commonclasses) => {
    let result = [(type || 'col')];
    brackpoint && result.push(brackpoint);
    result.push(getGridColNumber(monclerColumn) + '');
    return result.join('-');
};
const gridClassnames = (configs, commonclasses) => {
    return (configs
        .map((args) => {
        return gridClassname.apply(this, [...args]);
    })
        .join(' ') + (commonclasses ? ' ' + commonclasses : ''));
};
const autoChooseComponent = (componentsObj) => {
    return componentsObj[getId()];
};
const autoChooseProps = (props) => {
    return props[getId()];
};
export { autoChooseClassnames, autoChooseComponent, autoChooseProps, getGridColNumber, getId, getName, gridClassname, gridClassnames, is, nameIs };
