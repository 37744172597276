function price(productPrice) {
    var _a;
    return (productPrice === null || productPrice === void 0 ? void 0 : productPrice.type) === 'range' ? (_a = productPrice === null || productPrice === void 0 ? void 0 : productPrice.min) === null || _a === void 0 ? void 0 : _a.sales : productPrice === null || productPrice === void 0 ? void 0 : productPrice.sales;
}
function fullPrice(productPrice) {
    var _a;
    return (productPrice === null || productPrice === void 0 ? void 0 : productPrice.type) === 'range' ? (_a = productPrice === null || productPrice === void 0 ? void 0 : productPrice.min) === null || _a === void 0 ? void 0 : _a.list : productPrice === null || productPrice === void 0 ? void 0 : productPrice.list;
}
// function taxatedPrices(product: genericProductItem, variantPrice?: models.Price): { price: number; full_price: number; price_taxes: number } {
//   const grossPrice = price(variantPrice || product.price)?.value;
//   const grossFullPrice = fullPrice(variantPrice || product.price)?.value || price(variantPrice || product.price)?.value;
//   const netPrice = product.tax?.taxPolicy == 'GROSS' ? grossPrice / (1 + product.tax.taxRate) : grossPrice;
//   const netFullPrice = product.tax?.taxPolicy == 'GROSS' ? grossFullPrice / (1 + product.tax.taxRate) : grossFullPrice;
//   const taxes = grossPrice - netPrice;
//   return { price: netPrice, full_price: netFullPrice, price_taxes: taxes };
// }
function absDiscount(productPrice) {
    var _a, _b, _c;
    return ((_a = fullPrice(productPrice)) === null || _a === void 0 ? void 0 : _a.value) && parseFloat((((_b = fullPrice(productPrice)) === null || _b === void 0 ? void 0 : _b.value) - ((_c = price(productPrice)) === null || _c === void 0 ? void 0 : _c.value)).toFixed(3));
}
function getSelectedSize(product) {
    var _a, _b;
    return (_b = (_a = product.variationAttributes) === null || _a === void 0 ? void 0 : _a.filter((variation) => variation.attributeId === 'size')[0]) === null || _b === void 0 ? void 0 : _b.value;
}
function getOrderCoupon(obj) {
    var _a, _b, _c;
    return (_c = (_b = (_a = obj === null || obj === void 0 ? void 0 : obj.totals) === null || _a === void 0 ? void 0 : _a.discounts) === null || _b === void 0 ? void 0 : _b.filter((el) => el.type === 'coupon' && el.applied && el.promotionClass === 'ORDER')[0]) === null || _c === void 0 ? void 0 : _c.couponCode;
}
function getProductCoupon(product) {
    var _a, _b;
    return (_b = (_a = product === null || product === void 0 ? void 0 : product.appliedPromotions) === null || _a === void 0 ? void 0 : _a.filter((el) => el.basedOnCoupon)[0]) === null || _b === void 0 ? void 0 : _b.coupon_code;
}
function getCheckoutEvent(step) {
    const map = {
        checkout: 'begin_checkout',
        shipping: 'add_shipping_info',
        payment: 'add_payment_info',
    };
    return map[step];
}
export function generateListName(args) {
    if (args.item_list_name)
        return args.item_list_name;
    if (!!args.breadcrumb) {
        return `${(args === null || args === void 0 ? void 0 : args.pageCategory) || window.datalayerdata.pageCategory}/${args.breadcrumb.map((space) => space.categoryId).join('/')}`;
    }
}
export function getPageCategory() {
    var _a;
    return (_a = window.datalayerdata) === null || _a === void 0 ? void 0 : _a.pageCategory;
}
export function compileListName(componentName, pageCategory) {
    return componentName && `${pageCategory || getPageCategory()}/${componentName}`;
}
function productImpression(product, contextData) {
    var _a, _b, _c;
    let imp = Object.assign({ item_name: product === null || product === void 0 ? void 0 : product.originalModelName, item_id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product === null || product === void 0 ? void 0 : product.price)) === null || _a === void 0 ? void 0 : _a.value, full_price: ((_b = fullPrice(product === null || product === void 0 ? void 0 : product.price)) === null || _b === void 0 ? void 0 : _b.value) || ((_c = price(product === null || product === void 0 ? void 0 : product.price)) === null || _c === void 0 ? void 0 : _c.value), size: getSelectedSize(product) }, product === null || product === void 0 ? void 0 : product.analyticsAttributes);
    return cleanData(Object.assign(Object.assign({}, imp), contextData));
}
function cleanData(obj) {
    Object.keys(obj).forEach((key) => {
        if (obj[key] === undefined || obj[key] === '' || obj[key] === null)
            delete obj[key];
    });
    return obj;
}
let timer;
let count = 0;
if (document.readyState === 'complete' || window.dataLayer) {
    window.dataLayer.push({ event: 'dataLayerStatus', status: 'ready' });
}
function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
        // call on next available tick
        setTimeout(fn, 1);
    }
    else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}
function push(obj) {
    var _a, _b;
    count++;
    if (((_a = navigator.userAgent) === null || _a === void 0 ? void 0 : _a.toLowerCase().indexOf('bot')) > -1 || ((_b = navigator.userAgent) === null || _b === void 0 ? void 0 : _b.toLowerCase().indexOf('headless')) > -1) {
        // no GTM for crawler/headless
        return;
    }
    if (window.dataLayer) {
        clearTimeout(timer);
        if (obj.eventAction && !obj.eventLabel) {
            obj.eventLabel = 'undefined';
        }
        docReady(() => window.dataLayer.push(obj));
    }
    else if (!window.devmode) {
        if (count >= 5) {
            console.error('datalayer not ready', new Error().stack);
        }
        else {
            timer = setTimeout(() => push(obj), 100);
        }
    }
}
function checkoutStep(type) {
    switch (type) {
        case 'ShoppingBag':
            return 1;
        case 'Shipping&Delivery':
            return 2;
        case 'Payment':
            return 3;
        default:
            return '';
    }
}
function generateLabel(low, out, back) {
    let values = [];
    if (low) {
        values.push('low');
    }
    if (out) {
        values.push('out');
    }
    if (back) {
        values.push('back');
    }
    return values.join('/') + '-submit';
}
function generateDim98(sleeve_personalization, front_personalization, back_personalization) {
    const personalization = ((sleeve_personalization != '' && sleeve_personalization != undefined ? 's_t' : '') +
        '-' +
        (front_personalization != '' && front_personalization != undefined ? 'f_t' : '') +
        '-' +
        (back_personalization == 'MAXICOQ' ? 'b_l' : back_personalization != '' && back_personalization != undefined ? 'b_t' : ''))
        .replace('--', '-')
        .replace(/^\-/, '')
        .replace(/\-$/, '');
    return personalization == '' && sleeve_personalization != undefined && front_personalization != undefined && back_personalization != undefined ? 'none' : personalization;
}
const analytics = {
    /**
     * send general variables
     */
    sendGeneralVariables: () => {
        // console.info('_____ sendGeneralVariables', window.datalayerdata);
        push(window.datalayerdata);
    },
    /**
     * PDP - Product Detail Page
     */
    productDetailPage: (product) => {
        var _a, _b, _c, _d, _e;
        // console.info('_____ productDetailPage', product);
        push({
            ecommerce: null,
        });
        push({
            event: 'view_item',
            ecommerce: {
                // value: taxatedPrices(product).price,
                value: (_a = price(product === null || product === void 0 ? void 0 : product.price)) === null || _a === void 0 ? void 0 : _a.value,
                currency: (_b = price(product === null || product === void 0 ? void 0 : product.price)) === null || _b === void 0 ? void 0 : _b.currency,
                items: [
                    cleanData(Object.assign({ item_id: product === null || product === void 0 ? void 0 : product.masterId, 
                        // item_name: product?.originalModelName?.toLowerCase(),
                        // ...taxatedPrices(product),
                        price: (_c = price(product === null || product === void 0 ? void 0 : product.price)) === null || _c === void 0 ? void 0 : _c.value, full_price: ((_d = fullPrice(product === null || product === void 0 ? void 0 : product.price)) === null || _d === void 0 ? void 0 : _d.value) || ((_e = price(product === null || product === void 0 ? void 0 : product.price)) === null || _e === void 0 ? void 0 : _e.value), discount: absDiscount(product === null || product === void 0 ? void 0 : product.price) }, product === null || product === void 0 ? void 0 : product.analyticsAttributes)),
                ],
            },
        });
    },
    /**
     * PDP - Product Detail Change Size
     */
    productDetailChange: (product, productSize, variantPrice) => {
        var _a, _b, _c, _d, _e;
        // console.log('______ productDetailChange', variantPrice);
        push({
            ecommerce: null,
        });
        push({
            event: 'view_item',
            ecommerce: {
                // value: taxatedPrices(product, variantPrice).price,
                value: (_a = price(variantPrice || (product === null || product === void 0 ? void 0 : product.price))) === null || _a === void 0 ? void 0 : _a.value,
                currency: (_b = price(variantPrice || (product === null || product === void 0 ? void 0 : product.price))) === null || _b === void 0 ? void 0 : _b.currency,
                items: [
                    cleanData(Object.assign(Object.assign({ item_id: product === null || product === void 0 ? void 0 : product.masterId, 
                        // item_name: product?.originalModelName?.toLowerCase(),
                        // ...taxatedPrices(product, variantPrice),
                        price: (_c = price(variantPrice || (product === null || product === void 0 ? void 0 : product.price))) === null || _c === void 0 ? void 0 : _c.value, full_price: ((_d = fullPrice(variantPrice || (product === null || product === void 0 ? void 0 : product.price))) === null || _d === void 0 ? void 0 : _d.value) || ((_e = price(variantPrice || (product === null || product === void 0 ? void 0 : product.price))) === null || _e === void 0 ? void 0 : _e.value), discount: absDiscount(variantPrice || (product === null || product === void 0 ? void 0 : product.price)) }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { size: productSize === null || productSize === void 0 ? void 0 : productSize.id, item_category3: (productSize === null || productSize === void 0 ? void 0 : productSize.ATS) > 0 ? 'instock' : 'outofstock' })),
                ],
            },
        });
    },
    /**
     * BAG - Add To Bag
     */
    addToBag: (product, productSize, variantPrice, details) => {
        var _a, _b, _c, _d, _e;
        console.info('_____ productDetailPage', product);
        details = details || {};
        push({
            ecommerce: null,
        });
        push({
            event: 'add_to_cart',
            ecommerce: {
                // value: taxatedPrices(product, variantPrice).price,
                value: (_a = price(variantPrice || product.price)) === null || _a === void 0 ? void 0 : _a.value,
                currency: (_b = price(variantPrice || product.price)) === null || _b === void 0 ? void 0 : _b.currency,
                items: [
                    cleanData(Object.assign(Object.assign({ item_id: product === null || product === void 0 ? void 0 : product.masterId, 
                        // item_name: product?.originalModelName?.toLowerCase(),
                        // ...taxatedPrices(product, variantPrice),
                        price: (_c = price(variantPrice || product.price)) === null || _c === void 0 ? void 0 : _c.value, full_price: ((_d = fullPrice(variantPrice || product.price)) === null || _d === void 0 ? void 0 : _d.value) || ((_e = price(variantPrice || product.price)) === null || _e === void 0 ? void 0 : _e.value), discount: absDiscount(variantPrice || product.price), size: (productSize === null || productSize === void 0 ? void 0 : productSize.id) || getSelectedSize(product) }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { quantity: 1, detail: details.detail })),
                ],
            },
        });
    },
    /**
     * BAG - Remove from Bag
     */
    removeFromBag: (product, quantity) => {
        var _a, _b, _c, _d, _e;
        // console.info('_____ removeFromCart', product);
        push({
            ecommerce: null,
        });
        push({
            event: 'remove_from_cart',
            ecommerce: {
                // value: taxatedPrices(product).price,
                value: (_a = price(product.price)) === null || _a === void 0 ? void 0 : _a.value,
                currency: (_b = price(product.price)) === null || _b === void 0 ? void 0 : _b.currency,
                items: [
                    cleanData(Object.assign(Object.assign({ item_id: product === null || product === void 0 ? void 0 : product.masterId, 
                        // item_name: product?.originalModelName?.toLowerCase(),
                        // ...taxatedPrices(product),
                        price: (_c = price(product.price)) === null || _c === void 0 ? void 0 : _c.value, full_price: ((_d = fullPrice(product.price)) === null || _d === void 0 ? void 0 : _d.value) || ((_e = price(product.price)) === null || _e === void 0 ? void 0 : _e.value), discount: absDiscount(product.price), size: getSelectedSize(product) }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { quantity: quantity || 1 })),
                ],
            },
        });
    },
    /**
     * CART - View Cart
     */
    viewCart: (cart) => {
        var _a;
        // console.info('_____ cart', cart);
        push({
            ecommerce: null,
        });
        push({
            event: 'view_cart',
            ecommerce: {
                // value: cart.totals?.totalAmountAdjustedMerchandize - cart.totals?.totalTaxAmount?.value,
                value: (_a = cart === null || cart === void 0 ? void 0 : cart.totals) === null || _a === void 0 ? void 0 : _a.totalAmount,
                currency: cart.currencyCode,
                coupon: getOrderCoupon(cart),
                items: cart === null || cart === void 0 ? void 0 : cart.items.map((product) => {
                    var _a, _b, _c;
                    return cleanData(Object.assign(Object.assign({ item_id: product === null || product === void 0 ? void 0 : product.masterId, 
                        // item_name: product?.originalModelName?.toLowerCase(),
                        // price: price(product.price)?.value - product?.singleTaxValue,
                        // full_price: (fullPrice(product.price)?.value || price(product.price)?.value) - product?.singleTaxValue,
                        // price_taxes: product?.singleTaxValue,
                        price: (_a = price(product.price)) === null || _a === void 0 ? void 0 : _a.value, full_price: ((_b = fullPrice(product.price)) === null || _b === void 0 ? void 0 : _b.value) || ((_c = price(product.price)) === null || _c === void 0 ? void 0 : _c.value), discount: absDiscount(product.price), size: getSelectedSize(product) }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { quantity: product.quantity, coupon: getProductCoupon(product) }));
                }),
            },
        });
    },
    /**
     * CHECKOUT - View Checkout
     */
    checkout: (order, step, paymentMethod) => {
        var _a, _b, _c, _d, _e;
        if (!order)
            return;
        if (paymentMethod === 'scheme' || paymentMethod === 'CREDIT_CARD' || paymentMethod === 'kcp_creditcard' || paymentMethod === 'credit card') {
            paymentMethod = 'credit_card';
        }
        // console.info('_____ cart', cart);
        push({
            ecommerce: null,
        });
        push({
            event: getCheckoutEvent(step),
            ecommerce: {
                // value: order.totals?.totalAmountAdjustedMerchandize - order.totals?.totalTaxAmount?.value,
                value: (_a = order === null || order === void 0 ? void 0 : order.totals) === null || _a === void 0 ? void 0 : _a.totalAmountAdjustedMerchandize,
                currency: order.currencyCode,
                coupon: getOrderCoupon(order),
                shipping_tier: step === 'shipping' && (((_c = (_b = order.shipping[0]) === null || _b === void 0 ? void 0 : _b.selectedShippingMethod) === null || _c === void 0 ? void 0 : _c.dataLayerId) || ((_e = (_d = order.shipping[0]) === null || _d === void 0 ? void 0 : _d.selectedShippingMethod) === null || _e === void 0 ? void 0 : _e.ID)),
                payment_type: step === 'payment' && paymentMethod ? paymentMethod : undefined,
                items: order.items.map((product) => {
                    var _a, _b, _c;
                    return cleanData(Object.assign(Object.assign({ item_id: product === null || product === void 0 ? void 0 : product.masterId, 
                        // item_name: product?.originalModelName?.toLowerCase(),
                        // price: price(product.price)?.value - product?.singleTaxValue,
                        // full_price: (fullPrice(product.price)?.value || price(product.price)?.value) - product?.singleTaxValue,
                        // price_taxes: product?.singleTaxValue,
                        price: (_a = price(product.price)) === null || _a === void 0 ? void 0 : _a.value, full_price: ((_b = fullPrice(product.price)) === null || _b === void 0 ? void 0 : _b.value) || ((_c = price(product.price)) === null || _c === void 0 ? void 0 : _c.value), discount: absDiscount(product.price), size: getSelectedSize(product) }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { quantity: product.quantity, coupon: getProductCoupon(product) }));
                }),
            },
        });
    },
    /**
     * PURCHASE - Thankyoupage after order completed
     */
    purchase: (order) => {
        var _a, _b;
        push({
            ecommerce: null,
        });
        push({
            event: 'purchase',
            ecommerce: {
                transaction_id: order.orderNumber,
                value: order.analyticsTotalAmount,
                tax: order.analyticsTotalTaxAmount,
                shipping: (_a = order === null || order === void 0 ? void 0 : order.totals) === null || _a === void 0 ? void 0 : _a.totalShippingCostValue,
                currency: order === null || order === void 0 ? void 0 : order.currencyCode,
                coupon: getOrderCoupon(order),
                items: (_b = order === null || order === void 0 ? void 0 : order.items) === null || _b === void 0 ? void 0 : _b.map((product) => {
                    var _a;
                    return cleanData(Object.assign(Object.assign({ item_id: product === null || product === void 0 ? void 0 : product.masterId, item_name: (_a = product === null || product === void 0 ? void 0 : product.originalModelName) === null || _a === void 0 ? void 0 : _a.toLowerCase(), coupon: getProductCoupon(product), discount: product === null || product === void 0 ? void 0 : product.analyticsDiscount }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { price: product === null || product === void 0 ? void 0 : product.analyticsPrice, quantity: product.quantity, full_price: product === null || product === void 0 ? void 0 : product.analyticsFullPrice, price_taxes: product === null || product === void 0 ? void 0 : product.singleItemAdjustedValue, size: getSelectedSize(product) }));
                }),
            },
        });
    },
    /**
     * PRODUCT LIST - default impression (PLP)
     * Proxy -> used in common saga file
     */
    proxyProductImpressions: function (searchResults, progress, isScroll, isShelf) {
        const products = searchResults.products;
        const breadcrumb = searchResults.breadcrumbs;
        this.productImpressions(Object.assign({ products }, { index: progress, item_list_name: generateListName({ breadcrumb }) }));
    },
    /**
     * PRODUCT LIST - impression (PLP) on back from PDP
     * Proxy -> used in common saga file
     */
    proxyProductImpressionsOnBack: function (products, breadcrumb, progress) {
        //INDEX FROCED TO 0 BECAUSE ALL ITEMS ARE TRACKED ON BACK (AS NEW PAGE LOAD)
        this.productImpressions(Object.assign({ products }, { index: 0, item_list_name: generateListName({ breadcrumb }) }));
    },
    /**
     * PRODUCTS LIST - Product Imptession
     */
    productImpressions: (args) => {
        try {
            const { products, index, item_list_name } = args;
            const impressions = {
                event: 'view_item_list',
                ecommerce: {
                    currency: window.currency,
                    item_list_name,
                    items: products.map((product, inIndex) => productImpression(product, {
                        index: index + inIndex,
                        item_list_name,
                    })),
                },
            };
            push({ ecommerce: null });
            push(impressions);
            return impressions;
        }
        catch (e) {
            console.log('Anaytics productImpressions error');
            return {};
        }
    },
    /**
     * PRODUCTS LIST - Impression Change
     */
    productImpressionsChange: (args) => {
        try {
            const { product, index, size, item_list_name } = args;
            const dataLayer = {
                event: 'view_item_list',
                ecommerce: {
                    item_list_name,
                    currency: window.currency,
                    items: [
                        productImpression(product, {
                            index,
                            size,
                            item_list_name,
                        }),
                    ],
                },
            };
            push({ ecommerce: null });
            push(dataLayer);
            // console.info('_____ productImpressionsChange', dataLayer);
            return dataLayer;
        }
        catch (e) {
            console.log('Anaytics productImpressionsChange error');
            return {};
        }
    },
    /**
     * PRODUCT LIST - Product Click
     */
    productClick: (args) => {
        var _a;
        try {
            const { product, index, item_list_name } = args;
            const dataLayer = {
                event: 'select_item',
                ecommerce: {
                    item_list_name,
                    currency: (_a = price(product === null || product === void 0 ? void 0 : product.price)) === null || _a === void 0 ? void 0 : _a.currency,
                    items: [
                        productImpression(product, {
                            index,
                            item_list_name,
                        }),
                    ],
                },
            };
            push({ ecommerce: null });
            push(dataLayer);
            // console.info('_____ productImpressionsChange', dataLayer);
            return dataLayer;
        }
        catch (e) {
            console.log('Anaytics productClick error');
            return {};
        }
    },
    /**
     * PRODUCT LIST - Search product list impression
     * Proxy -> used in common saga file
     */
    proxyProductSearchImpressions: function (products, progress) {
        this.productSearchImpressions({ products, index: progress });
    },
    /**
     * PRODUCT LIST - Search product list impression
     */
    productSearchImpressions: function (args) {
        args.item_list_name = 'search results';
        return this.productImpressions(args);
    },
    /**
     * PRODUCT LIST - Recommended product list impression
     */
    productRecommendedImpressions: function (args) {
        return this.productImpressions(args);
    },
    /**
     * PRODUCT LIST - Wishlist product list impression
     */
    productWishlistImpressions: function (args) {
        return this.productImpressions(args);
    },
    /**
     * PRODUCT LIST - Editorial product list impression
     */
    productRailImpressions: function (args) {
        return this.productImpressions(args);
    },
    /**
     * NEWSLETTER
     */
    newsletter: function (state) {
        push({
            event: 'newsletter subscription',
            action: state,
        });
    },
    /**
     * SEARCH - Click
     */
    search: function (action) {
        push({
            event: 'search',
            action,
        });
    },
    /**
     * SEARCH - Result
     */
    searchResult: function (searchTerm, count) {
        push({
            event: 'search result',
            action: count >= 1 ? 'results' : 'no results',
            detail: searchTerm.toLowerCase(),
        });
    },
    /**
     * LOGIN FLOW
     */
    loginOrRegister: function (method, action) {
        action = action || 'loginorregister';
        push({
            event: 'loginorregister',
            action: `${method}-${action}`,
        });
    },
    /**
     * PROMOTION
     */
    promotion: function (method, index, label) {
        push({
            event: method,
            action: `${index}-${label === null || label === void 0 ? void 0 : label.toLowerCase()}`,
        });
    },
    /**
     * GENERIC PRODUCT EVENT
     */
    genericProductEvent: function (event, pidColorVariation, action) {
        push({
            event: event,
            action: action,
            detail: pidColorVariation,
        });
    },
    /**
     * PRODUCT ACTION CLICK
     */
    productActionIntent: function (eventName, pidColorVariation, action) {
        action = action || 'click';
        this.genericProductEvent(eventName, pidColorVariation, action);
    },
    /**
     * ADD TO WISHLIST
     */
    addToWishlist: function (id, context) {
        this.genericProductEvent('save for later', id, `save for later-${context || window.datalayerdata.pageCategory}`);
    },
    /**
     * RESERVE IN STORE
     */
    reserveInStore: function (pidColorVariation, action) {
        action = action || 'start';
        this.genericProductEvent('reserve in store', pidColorVariation, action);
    },
    /**
     * NOTIFY ME
     */
    notifyMe: function (pidColorVariation, action) {
        action = action || 'start';
        this.genericProductEvent('notify me', pidColorVariation, action);
    },
    expressCheckout: function (action, page, paymentMethod) {
        const actionString = `${action}-${page}${paymentMethod ? '-' + paymentMethod.toLowerCase() : ''}`;
        push({
            event: 'express checkout',
            action: actionString,
        });
    },
    refinementClick: function (action, value) {
        push({
            event: 'plp interaction',
            action,
            detail: value.toLowerCase(),
        });
    },
    findMySize: function (mfc, action, size) {
        push({
            event: 'pdp interaction',
            action: `${action}-find my size`,
            detail: `${mfc}${action === 'select' && !!size ? `-${size === null || size === void 0 ? void 0 : size.toLowerCase()}` : ''}`,
        });
    },
};
export default analytics;
