import cookie from 'react-cookies';
export function oneTrustHandler() {
    // window.OneTrustDisableLock is false by default, enable it to avoid scroll locking
    if (window.cookiePolicy === 'OneTrust') {
        try {
            if (window.OneTrust && !window.OneTrust.IsAlertBoxClosed() && !window.OneTrustDisableLock) {
                document.body.classList.add('no-overflow-container');
            }
        }
        catch (error) {
            console.log('ERROR: ' + error);
        }
        window.addEventListener('onetrust.changed', function () {
            document.body.classList.remove('no-overflow-container');
            const encodedURI = encodeURIComponent(window.OnetrustActiveGroups);
            fetch(window.homeurl + '/consent/onetrust?optanonag=' + encodedURI)
                .then((res) => res.json())
                .then((response) => {
                console.log(response);
                cookie.save('OneTrustChoices', JSON.stringify(response), { path: '/' });
                window.dispatchEvent(new CustomEvent('OneTrust:changed', { detail: response }));
            })
                .catch((err) => {
                if (typeof err == 'string') {
                    console.log(JSON.parse(err));
                }
                else {
                    console.error('Consent url not found');
                }
            });
        });
    }
}
