__webpack_public_path__ = `${document.documentElement.dataset.publicpath}`;
import 'what-input';
import Aos from 'aos';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ComponentDispatcher } from 'html-scoped-component-importer';
import smoothscroll from 'smoothscroll-polyfill';
import AnalyticsDataService from './services/analyticsDataService';
import ArnClientService from './services/ArnClientService';
import AppService from './services/appService';
import OverlayService from './services/overlayService';
import customEventBus from './libs/eventBus';
gsap.registerPlugin(ScrollTrigger);
window.eventQueue = new Map();
new AnalyticsDataService();
new ArnClientService();
new AppService();
new OverlayService();
smoothscroll.polyfill();
const addMarginBottomForFooter = () => {
    if (window.brand === 'moncler') {
        const body = document.querySelector('body');
        let maincontent = document.getElementById('maincontent');
        let footer = document.querySelector('.footer');
        let header = document.querySelector('header');
        if (['cart-show'].includes(body.id) || body.classList.contains('checkout') || window.innerWidth < window.mqObj.md) {
            maincontent === null || maincontent === void 0 ? void 0 : maincontent.style.removeProperty('margin-bottom');
            footer === null || footer === void 0 ? void 0 : footer.style.removeProperty('position');
        }
        else if (footer && maincontent && footer.offsetHeight >= window.innerHeight - header.offsetHeight) {
            maincontent.style.marginBottom = '0';
            footer.style.position = 'relative';
        }
        else if (window.innerWidth >= window.mqObj.md && footer && maincontent) {
            maincontent.style.marginBottom = `${footer.clientHeight}px`;
            footer.style.position = 'fixed';
        }
    }
};
//FixVoChrome
(() => {
    let inputMode = '';
    let keyboradCheckingHandler = (e) => {
        document.removeEventListener('keydown', keyboradCheckingHandler);
        document.addEventListener('touchstart', pointerCheckHandler);
        document.addEventListener('pointermove', pointerCheckHandler);
        if (inputMode != 'keyboard') {
            inputMode = 'keyboard';
            document.body.classList.add('keyboard-mode');
            setTimeout(() => {
                customEventBus.emit('keyboard:mode', { isKeyboardMode: true, target: e.target, key: e.code });
            }, 0);
        }
    };
    let pointerCheckHandler = () => {
        document.addEventListener('keydown', keyboradCheckingHandler);
        document.removeEventListener('pointermove', pointerCheckHandler);
        document.removeEventListener('touchstart', pointerCheckHandler);
        if (inputMode != 'pointer') {
            inputMode = 'pointer';
            document.body.classList.remove('keyboard-mode');
            setTimeout(() => {
                customEventBus.emit('keyboard:mode', { isKeyboardMode: false });
            }, 0);
        }
    };
    document.body.classList.add('keyboard-mode');
    document.addEventListener('pointermove', pointerCheckHandler);
    document.addEventListener('touchstart', pointerCheckHandler);
})();
window.addEventListener('DOMContentLoaded', (event) => {
    addMarginBottomForFooter();
    let scenes = Array.from(document.querySelectorAll('.new-parallax-animations .parallax-element'));
    scenes.forEach((element, i) => {
        const sectionBg = element.querySelector('.video-wrapper') || element.querySelector('img');
        // sectionBg.style.transform = 'translateY(0px) scale(1.2)';
        gsap.to(sectionBg, {
            translateY: `${innerHeight / 2}px`,
            // scale: 1.2,
            ease: 'none',
            scrollTrigger: {
                trigger: element,
                start: 'top top',
                end: 'bottom top',
                scrub: true,
            },
        });
    });
    Aos.init({
        offset: 0,
        delay: 100,
    });
    const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
            if (entry.devicePixelContentBoxSize) {
                addMarginBottomForFooter();
            }
        }
    });
    let footer = document.querySelector('.footer');
    if (footer) {
        if (resizeObserver) {
            resizeObserver.observe(footer);
        }
    }
});
window.addEventListener('resize', () => {
    // console.log('.resize');
    addMarginBottomForFooter();
});
document.addEventListener('lazybeforeunveil', function (e) {
    if (e.target.parentElement.classList.contains('product-image'))
        e.target.parentElement.classList.add('parent-lazyloaded');
});
const compDispatcher = new ComponentDispatcher();
compDispatcher.createAsyncComponents();
// Can be used to load TS Components programmatically, cfr. componentsUtils
document.addEventListener('loadAsyncComponent', function (e) {
    compDispatcher.createAsyncComponents(e.detail);
});
