import analytics from '@utils/analytics';
import stoneAnalytics from '@stone/utils/analytics';
import whatInput from 'what-input';
import BaseService from '../base/baseService';
import { appPostStructuredMessage } from '@common/utils/appUtils';
import { is } from '@common/utils/brandDiffsUtils';
const decodeEntities = (string) => {
    const entities = {
        amp: '&',
        apos: "'",
        '#x27': "'",
        '#x2F': '/',
        '#39': "'",
        '#47': '/',
        lt: '<',
        gt: '>',
        nbsp: ' ',
        quot: '"',
    };
    return string.replace(/&([^;]+);/gm, (match, entity) => {
        return entities[entity] || match;
    });
};
export default class AnalyticsDataService extends BaseService {
    constructor() {
        super();
        this.monitorClick();
        this.initEditorialBannersImpression();
        this.initSocialLoginSuccessIspector();
    }
    monitorClick() {
        document.addEventListener('click', function (e) {
            var _a, _b, _c, _d;
            const nativeTarget = e.target;
            let target = nativeTarget;
            if (!window.dataLayer)
                return;
            // webview app analytics
            if (target === null || target === void 0 ? void 0 : target.matches('[data-analytics-app]')) {
                const analyticsObj = JSON.parse(target.getAttribute('data-analytics-app'));
                if (window.appmode) {
                    appPostStructuredMessage('firebase_analytics', Object.assign({}, analyticsObj));
                }
            }
            //check simple data-analytics event
            if (!(target === null || target === void 0 ? void 0 : target.matches('[data-analytics]'))) {
                target = target.closest('[data-analytics');
            }
            if (target === null || target === void 0 ? void 0 : target.matches('[data-analytics]')) {
                if (target === null || target === void 0 ? void 0 : target.matches('[data-analytics-media]')) {
                    const media = target.getAttribute('data-analytics-media');
                    const currentInput = whatInput.ask();
                    if (media == 'd' && (window.isMobile() || currentInput === 'keyboard' || currentInput === 'touch')) {
                        return false;
                    }
                }
                const analyticsObj = target.getAttribute('data-analytics').replace('__pageCategory__', window.datalayerdata.pageCategory);
                const analyticsObject = JSON.parse(analyticsObj);
                if (analyticsObject.eventAction && !analyticsObject.eventLabel) {
                    analyticsObject.eventLabel = 'undefined';
                }
                // console.log('_____ analyticsObject', analyticsObject);
                window.dataLayer.push(analyticsObject);
                return false;
            }
            target = nativeTarget;
            //check analytics editorial impression
            if (!(target === null || target === void 0 ? void 0 : target.matches('[data-analytics-id]'))) {
                target = target.closest('[data-analytics-id');
            }
            if (target === null || target === void 0 ? void 0 : target.matches('[data-analytics-id]')) {
                const editorialID = target.getAttribute('data-analytics-id');
                const impression = (_a = window.editorialComponents[editorialID]) === null || _a === void 0 ? void 0 : _a.promoImpression;
                if (impression) {
                    let impressionDecoded = JSON.parse(decodeEntities(JSON.stringify(impression)));
                    analytics.internalPromoClick(impressionDecoded);
                    // OE-12811 - added for different collections in collections-inpage-navigation component
                    if (nativeTarget === null || nativeTarget === void 0 ? void 0 : nativeTarget.dataset.analyticsPromoName) {
                        impressionDecoded = Object.assign(Object.assign({}, impressionDecoded), { name: nativeTarget.dataset.analyticsPromoName });
                    }
                    analytics.internalBannerInteraction(impressionDecoded, ((_c = (_b = nativeTarget === null || nativeTarget === void 0 ? void 0 : nativeTarget.dataset) === null || _b === void 0 ? void 0 : _b.analyticsLabel) === null || _c === void 0 ? void 0 : _c.toLowerCase()) || ((_d = nativeTarget === null || nativeTarget === void 0 ? void 0 : nativeTarget.innerText) === null || _d === void 0 ? void 0 : _d.toLowerCase()));
                }
                else {
                    console.warn(`Missing impression of ID: ${editorialID}`);
                }
                return false;
            }
        });
    }
    initEditorialBannersImpression() {
        if (!window.editorialComponentWrappers ||
            window.editorialComponentWrappers.length === 0 ||
            !window.editorialComponents ||
            Object.keys(window.editorialComponents).length === 0) {
            return;
        }
        let bannerCounter = 1, sliderCounter = 1;
        //array of impression to push on dataLayer
        let pageImpressions = [];
        //loop through editorial wrapper list, append single slide and create impression array
        for (let index = 0; index < window.editorialComponentWrappers.length; index++) {
            const wrapper = window.editorialComponentWrappers[index];
            if (wrapper.children) {
                wrapper.children = wrapper.children.map((id, pos) => {
                    const component = window.editorialComponents[id];
                    if (!component)
                        return;
                    //remove HTML tags from name and add _ between texts
                    component.promoImpression.name = component.promoImpression.name.replace(/<[^>]*>?/gm, '').toLowerCase();
                    //only first hero component need to be hero_image, the other must be 'promo'
                    if (component.promoImpression.id === 'hero_image' && index > 0) {
                        component.promoImpression.id = 'promo';
                    }
                    //if it's a slider, add only first slide to impression
                    if (wrapper.isSlider) {
                        component.promoImpression.position = `slider_${sliderCounter}_${pos + 1}`;
                        if (pos === 0) {
                            pageImpressions.push(component.promoImpression);
                        }
                        if (pos === wrapper.children.length - 1) {
                            sliderCounter++;
                        }
                        //if not add all subcomponent to impression
                    }
                    else {
                        component.promoImpression.position = `banner_${bannerCounter}`;
                        bannerCounter++;
                        pageImpressions.push(component.promoImpression);
                    }
                    return component;
                });
            }
            else {
                //if has not children then is lunettes/lookbook/video module then add directly to impression
                wrapper.promoImpression.position = `banner_${bannerCounter}`;
                bannerCounter++;
                pageImpressions.push(wrapper.promoImpression);
            }
        }
        window.pageImpressions = pageImpressions;
        const pageImpressionsDecoded = JSON.parse(decodeEntities(JSON.stringify(pageImpressions)));
        analytics.internalPromoView(pageImpressionsDecoded);
    }
    initSocialLoginSuccessIspector() {
        window.addEventListener('DOMContentLoaded', (event) => {
            var _a;
            if (is('ST')) {
                try {
                    let _url = new URL(location.href);
                    let socialname = (_a = _url === null || _url === void 0 ? void 0 : _url.searchParams.get('oauthProvider')) === null || _a === void 0 ? void 0 : _a.toLowerCase();
                    socialname && stoneAnalytics.loginOrRegister(socialname, 'logged');
                }
                catch (e) {
                    console.log('error during logged-in social tracking');
                }
            }
        });
    }
}
